<template x-if="segnalazione">
    <section id="segnalazione-singola">
        <div class="container">
            <router-link :to="{ name: 'Segnalazioni' }" class="pulsante secondario mb-4"><i
                    class="fas fa-chevron-left"></i> Tutte le segnalazioni</router-link>
            <p v-if="isLoading">Caricamento...</p>
            <div v-if="!isLoading">
                <div class="testata">
                    <div class="c-1">
                        <p>Identificativo: <strong>eURP-{{ segnalazione.id }}</strong></p>
                        <p><i class="far fa-calendar-alt"></i> {{ segnalazione.data }}</p>
                        <h2>{{ segnalazione.argomento[0].nome }}</h2>
                        <h3>{{ segnalazione.categoria[0].nome }}</h3>
                        <p><i class="fas fa-map-marker-alt"></i> {{ segnalazione.indirizzo }}</p>
                        <!-- <p><i class="fas fa-user"></i> {{ segnalazione.nome }} {{ segnalazione.cognome }}</p> -->
                        <div v-if="segnalazione.stato == 0" class="stato aperta">
                            <div class="cerchio"></div>
                            Segnalazione aperta
                        </div>
                        <div v-if="segnalazione.stato == 1" class="stato lavorazione">
                            <div class="cerchio"></div>
                            Segalazione in lavorazione
                        </div>
                        <div v-if="segnalazione.stato == 2" class="stato chiusa">
                            <div class="cerchio"></div>
                            Segnalazione chiusa
                        </div>
                        <div class="messaggi">
                            <div v-if="segnalazione.messaggio" class="msg-utente"><span
                                    v-html="segnalazione.messaggio"></span></div>
                            <template v-if="segnalazione.risposte.length > 0">
                                <p class="operatore">Messaggi operatore</p>
                                <div v-for="risposta in segnalazione.risposte" :key="risposta.id" class="msg-sistema">
                                    <p v-html="risposta.messaggio"></p>
                                    <p class="data">{{ risposta.data }}</p>
                                </div>
                            </template>

                        </div>
                    </div>
                    <div class="c-2">
                        <div class="map-wrapper">
                            <l-map ref="map" @ready="loadMap" :zoom="18" :center="[segnalazione.lat, segnalazione.lng]"
                                :maxZoom="18" id="map" :options="{ LControlLayers: false, scrollWheelZoom: false }">
                                <l-tile-layer :url="tileurl" :attribution="attribution"></l-tile-layer>

                                <l-marker :lat-lng="[segnalazione?.lat, segnalazione?.lng]" :autoPan="true">
                                    <template v-if="segnalazione.icona_file">
                                        <l-icon :icon-anchor="iconAnchor" :icon-size="iconSize" :icon-url="segnalazione.icona_file">
                                        </l-icon>
                                    </template>
                                    <template v-else>
                                        <l-icon>
                                            <div class="pin-marker" :class="{ active: segnalazione.id === idSegnalazioneAttiva }">
                                                <img class="pin"
                                                    :src="require(`../assets/img/icone/pin-${segnalazione.stato}.svg`)"
                                                    alt="">
                                                <i :class="segnalazione.icona" class="icona"></i>
                                            </div>
                                        </l-icon>
                                    </template>
                                </l-marker>
                            </l-map>
                        </div>
                        <div class="allegati">
                            <template v-for="(a, index) in segnalazione.allegati" :key="index">
                                <img :src="a" alt="" @click="openLightboxOnSlide(index + 1)">
                            </template>
                        </div>

                        <FsLightbox :toggler="toggler" :sources="segnalazione.allegati" :slide="slide" type="image" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import FsLightbox from "fslightbox-vue/v3";
import { mapState } from 'vuex'

export default {
    components: { LMap, LTileLayer, LMarker, LIcon, FsLightbox },
    props: ['id', 'categoria'],
    data() {
        return {
            isLoading: false,
            tileurl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            toggler: false,
            imgIndex: 0,
            iconAnchor: [20, 39],
            iconSize: [35, 40]
        }
    },
    computed: {
        ...mapState([
            'segnalazioni'
        ]),
        segnalazione() {
            return this.$store.getters.getSegnalazioneById(this.id)
        }
    },
    methods: {
        loadMap() {
            this.map = this.$refs.map.leafletObject;
        },
        openLightboxOnSlide: function (number) {
            this.slide = number;
            this.toggler = !this.toggler;
            console.log(number)
        }
    },
    mounted() {
        console.log(this.segnalazione)
    }
}
</script>

<style></style>