<template>
  <div class="box-segnalazione">
    <div class="c-1">
        <h3><i :class="icona"></i> {{ argomento }}</h3>
        <p><i class="far fa-clock"></i> {{ data }}</p>
        <p><strong>Categoria:</strong> {{ categoria }}</p>
        <p v-if="indirizzo"><strong>Indirizzo:</strong> {{ indirizzo }}</p>
        <p v-html="messaggio"></p>
    </div>
    <div class="c-2">
        <div v-if="stato == 0" class="stato aperta">
            Aperta
            <div class="cerchio"></div>
        </div>
        <div v-if="stato == 1" class="stato lavorazione">
            In lavorazione
            <div class="cerchio"></div>
        </div>
        <div v-if="stato == 2" class="stato chiusa">
            Chiusa
            <div class="cerchio"></div>
        </div>
        <router-link :to="{ name: 'SegnalazioniDetails', params: {id: idSegnalazione}}" class="pulsante">Dettagli</router-link>
    </div>
  </div>
</template>

<script>
export default {
    props: ['idSegnalazione', 'categoria', 'argomento', 'data', 'indirizzo', 'stato', 'icona', 'messaggio']
}
</script>

<style>

</style>