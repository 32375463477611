<template>
  <section id="lista-segnalazioni">
    <div class="container">
      <h2 class="titolo-pagina">Lista segnalazioni</h2>
      
      <form class="ricerca-segnalazione mb-4" @submit.prevent="ricercaSegnalazioni">
        <div class="row g-3 mb-2">
          <div class="col-lg-4">
            <label for="testoRicerca" class="form-label"><strong>Testo segnalazione</strong></label>
            <input type="text" class="form-control" id="testoRicerca" v-model="testoRicerca" autocomplete="off" placeholder="Inserisci testo da cercare...">
          </div>
          <div class="col-lg-3">
            <label for="formCategoria" class="form-label"><strong>Categoria segnalazione</strong></label>
            <select id="formCategoria" class="form-select" v-model="insCategoria">
              <option value="" selected>Tutte le categorie</option>
                <option
                  v-for="categoria in categorie"
                  :key="categoria.id"
                  :value="categoria.id"
                >
                {{ categoria.nome }}
                </option>
            </select>
          </div>
          <div v-if="insCategoria" class="col-lg-3">
            <label for="formArgomenti" class="form-label"><strong>Argomento segnalazione</strong></label>
            <select id="formArgomenti" class="form-select" v-model="insArgomento">
                <option value="" disabled selected hidden>Selezionare un campo</option>
                <option
                  v-for="argomento in argomenti"
                  :key="argomento.id"
                  :value="argomento.id"
                >
                {{ argomento.nome }}
                </option>
            </select>
          </div>
          <div class="col-lg-2">
            <label for="formIndirizzo" class="form-label"><strong>Con indirizzo</strong></label>
            <select id="formIndirizzo" class="form-select" v-model="insIndirizzo">
                <option value="" selected>Tutte</option>
                <option value="si" selected>Con indirizzo</option>
                <option value="no" selected>Senza indirizzo</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="pulsante me-2">Cerca</button>
            <button @click.prevent="resetRicerca" class="pulsante rosso">Reset</button>
          </div>
        </div>
      </form>

      <p v-if="filteredSegnalazioni.length <= 0">Nessuna segnalazione trovata</p>
      <p v-if="isLoading">Caricamento...</p>
      <div class="wrapper" v-if="!isLoading">
        <SegnalazioneSingola
          v-for="segnalazione in filteredSegnalazioni"
          :key="segnalazione.id"
          :idSegnalazione="segnalazione.id"
          :categoria="segnalazione.categoria[0].nome"
          :argomento="segnalazione.argomento[0].nome"
          :messaggio="segnalazione.messaggio"
          :data="segnalazione.data"
          :indirizzo="segnalazione.indirizzo"
          :stato="segnalazione.stato"
          :icona="segnalazione.icona"
          >
        </SegnalazioneSingola>
      </div>
  </div>
  </section>
</template>

<script>
import SegnalazioneSingola from "../components/SegnalazioneSingola.vue";
import { mapState } from 'vuex'
export default {
components: { SegnalazioneSingola },
data() {
    return {
      isLoading: false,
      testoRicerca: '',
      insCategoria: '',
      insArgomento: '',
      insIndirizzo:'',
      filteredSegnalazioni: this.$store.state.segnalazioni
    }
  },
  watch: {
    insCategoria() {
      if (this.insCategoria) {
        this.categoriaFilter = this.categorie.find(categoria => categoria.id === this.insCategoria)
        this.argomenti = this.categoriaFilter.argomenti
        this.tempDescCategoria = this.categoriaFilter.descrizione
        this.argomentoFilter = this.argomenti[0]
        this.insArgomento = this.argomenti[0].id
      }
      else if (this.insCategoria == "") {
        this.insArgomento = ""
      }
      console.log(this.insArgomento)
    },
    insArgomento() {
      if (this.insArgomento) {
        this.argomentoFilter = this.argomenti.find(argomento => argomento.id === this.insArgomento)
      }
    }
  },
  computed: {
    ...mapState([
        'segnalazioni',
        'categorie'
    ]),
  },
  methods: {
    ricercaSegnalazioni() {
      this.filteredSegnalazioni = this.segnalazioni.filter(
          segnalazione => 
          (
            // Controlla il testo nel campo input
            (
            segnalazione.messaggio?.toLowerCase().includes(this.testoRicerca.toLowerCase()) ||
            segnalazione.categoria[0].nome?.toLowerCase().includes(this.testoRicerca.toLowerCase()) ||
            segnalazione.argomento[0].nome?.toLowerCase().includes(this.testoRicerca.toLowerCase())
            )
            // Controlla categorie, argomenti e se l'argomento utilizza la Mappa
            && (!this.insCategoria || segnalazione.categoria[0].id === this.insCategoria)
            && (!this.insArgomento || segnalazione.argomento[0].id === this.insArgomento)
            && (!this.insIndirizzo || segnalazione.argomento[0].uso_mappa === this.insIndirizzo)
          )
      )
      // if (this.insCategoria) {
      //   this.filteredSegnalazioni = this.filteredSegnalazioni.filter(segnalazione => segnalazione.categoria.id === this.insCategoria)
      // }
      // if (this.insArgomento) {
      //   this.filteredSegnalazioni = this.filteredSegnalazioni.filter(segnalazione => segnalazione.argomento.id === this.insArgomento)
      // }
    },
    resetRicerca() {
      this.testoRicerca = ""
      this.insCategoria = ""
      this.insArgomento = ""
      this.insIndirizzo = ""
      this.filteredSegnalazioni = this.segnalazioni      
    }
  },
  mounted() {
    if (this.$route.query.search) {
      this.testoRicerca = this.$route.query.search
    }
  },
}
</script>